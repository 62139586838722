import * as React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import VimeoVideo from "../components/vimeoVideo";

import "./works.css";

const PortfolioPage = () => {
  return(
    <Layout>
      <Seo title="Portfolio" />
			<div className="row">
				<div className="col-50">
					<VimeoVideo urlId={"395087343"} title={"camp-met-video"} />
				</div>
				<div className="col-50">
					<VimeoVideo urlId={"386856466"} title={"procrastination-video"} />
				</div>
			</div>
			<div className="row">
				<div className="col-50">
					<VimeoVideo urlId={"370178510"} title={"path-video"} />
				</div>
				<div className="col-50">
					<VimeoVideo urlId={"284227240"} title={"naxos-video"} />
				</div>
			</div>
			<div className="row">
			<div className="col-50">
					<p><Link to="https://vimeo.com/nickyiannakis" target="_blank" style={{ color: `inherit`, textDecoration: `none` }}> View more on Vimeo </Link></p>
				</div>
				<div className="col-50" style={{ textAlign: "right"}}>
					<p><Link to="https://www.instagram.com/create.flow/" target="_blank" style={{ color: `inherit`, textDecoration: `none` }}> View more on Instagram </Link></p>
				</div>
			</div>
		</Layout>
  );
};

export default PortfolioPage;