import * as React from "react"

import "./vimeoVideo.css";

const VimeoVideo = ({ urlId, title }) => {
	return (
		<div className="embedded-video-div">
			<iframe
				src={`https://player.vimeo.com/video/${urlId}?portrait=false&speed=false&title=false&byline=false`}
				className="embedded-video-iframe"
				frameborder="0"
				allow="autoplay; fullscreen"
				allowfullscreen
				title={title}
			></iframe>
		</div>
	);
}

export default VimeoVideo;